import { showData } from "../modules/ShowData";
import gsap from "gsap";
import { BASE_PATH, CURRENT_OVERLAY, FIRST_ROUTE } from "../utils/Contants";
import { router, state } from "../Main";
import { gallery3d } from "../modules/gallery/Gallery3d";
import {
  Dropdown,
  DROPDOWN_SELECT,
  DropdownItem
} from "../components/Dropdown";
import { Autocomplete } from "../components/Autocomplete";
import { colorizer, THEME_DARK } from "../modules/Colorizer";
import { SlidePage } from "./SlidePage";
import { WindowManager } from "../utils/WindowManager";
import { Globals } from "../utils/Globals";
import { ProjectIndexItem } from "../modules/ProjectIndexItem";

export const PROJECT_INDEX_SELECT: string = "project-index-select";

class ProjectIndexPage extends SlidePage {
  public element: HTMLElement;
  private categoriesMenu: HTMLElement;
  private categoryLinks: NodeListOf<HTMLElement>;
  private dropdownEls: NodeListOf<HTMLElement>;
  private dropdowns: { [key: string]: Dropdown } = {};
  private Autocomplete: Autocomplete;
  private showHeadings: boolean = true;
  private hasResults: boolean;
  private filterSticky: HTMLElement;
  private filterStickyHeight: number;
  private filterToggleButton: HTMLElement;
  private currentCategoryId: String = "";
  private filterBar: HTMLElement;
  private filters: HTMLElement;
  private siteLogo: HTMLElement;
  private hideProgrammeUnits: Array<string> = [
    "design-for-performance-and-interaction-march-2022"
  ];

  constructor(element) {
    super(element);

    this.dropdownEls = document.querySelectorAll(".Dropdown");
    this.filterSticky = element.querySelector(".filter-sticky");
    this.filterToggleButton = element.querySelector(".filter-toggle");
    this.filterBar = element.querySelector(".filter-bar");
    this.filters = element.querySelector(".filter-bar .filters-wrapper");
    this.siteLogo = document.querySelector(".main-logo");

    this.Autocomplete = new Autocomplete(
      document.querySelector(".Autocomplete"),
      this.onAutocompleteUpdate.bind(this)
    );

    this.dropdownEls.forEach(dropdownEL => {
      this.dropdowns[dropdownEL.getAttribute("id")] = new Dropdown(dropdownEL);
    });

    this.categoriesMenu = this.element.querySelector(".categories-menu");
    this.categoryLinks = this.categoriesMenu.querySelectorAll("a");
  }

  public initialize() {
    // Filter toggle
    this.element.addEventListener("scroll", this.onScroll.bind(this));
    WindowManager.signalResize.add(this.onResize.bind(this));
    this.filterToggleButton.addEventListener(
      "click",
      this.toggleFilterBar.bind(this)
    );

    // CATEGORY MENU
    this.categoryLinks.forEach((categoryLink: HTMLAnchorElement) => {
      if (
        showData.json.show.tags.length === 0 &&
        categoryLink.dataset.category === "tags"
      ) {
        // Hide themes if there are none
        categoryLink.style.display = "none";
        this.dropdowns["filter-categories"].removeItem("tags");
      } else {
        categoryLink.addEventListener("click", () => {
          this.categoryChange(categoryLink);
          this.dropdowns["filter-categories"].updateSelectedItem(
            categoryLink.innerHTML
          );
        });
      }
    });

    const categoriesDropdown = this.dropdowns["filter-categories"];
    categoriesDropdown.on(DROPDOWN_SELECT, e => {
      const catLink = [...this.categoryLinks].filter(
        el => el.dataset.category == e.value
      )[0];
      this.categoryChange(catLink);
    });

    // STUDENTS AUTO COMPLETE
    const studentNameInput = this.element.querySelector(
      ".student-query"
    ) as HTMLInputElement;
    studentNameInput.addEventListener("keyup", e => {
      const results = showData.getStudents(studentNameInput.value);
      if (studentNameInput.value.length) {
        this.showHeadings = false;
      }
      this.Autocomplete.populate(results);
    });

    this.setupDropdowns();
    this.setupAwardFilters();
    this.setupTagFilters();

    this.resetShowFilters();
    this.resetAwardFilters();
    this.resetTagFilters();
    this.resetStudents();
  }

  private setActiveCategory(category) {
    this.categoryLinks.forEach((categoryLink: HTMLAnchorElement) => {
      const cat = categoryLink.dataset.category;
      if (cat === category) {
        categoryLink.classList.add("active");
        this.categoryChange(categoryLink);
      } else {
        categoryLink.classList.remove("active");
      }
    });
  }

  private categoryChange(categoryLink) {
    if (categoryLink) {
      this.currentCategoryId = categoryLink.dataset.category;
    }

    const cat = categoryLink.dataset.category;
    const activeLink = this.categoriesMenu.querySelector("a.active");
    activeLink.classList.remove("active");
    categoryLink.classList.add("active");
    this.element.querySelectorAll(".filter.active").forEach(filter => {
      filter.classList.remove("active");
    });
    this.element
      .querySelectorAll(".filter[data-category='" + cat + "']")
      .forEach(filter => {
        filter.classList.add("active");
      });

    this.showHeadings = true;

    this.resetShowFilters();
    this.resetAwardFilters();
    this.resetTagFilters();

    switch (cat) {
      case "programmes":
        this.updateResults(showData.getProjectsByProgrammeAndUnit());
        break;

      case "awards":
        this.updateResults(showData.getProjectsByAwardType());
        break;

      case "tags":
        this.updateResults(showData.getProjectsByTag());
        break;

      case "students":
        this.updateResults(showData.getProjectsByStudent());
        break;
    }
  }

  private onAutocompleteUpdate(val) {
    this.updateResults(showData.getProjectsByStudent(val));
  }

  // CATEGORY FILTERS - PROGRAMMES
  // old shows dropdown for bpro/autumn - can probably be removed
  // private setupShowsFilters() {
  //   const showsDropdown = this.dropdowns["shows"];
  //   showsDropdown.on(DROPDOWN_SELECT, e => {
  //     this.updateResults(
  //       showData.getProjectsByProgrammeAndUnit(null, null, e.value)
  //     );
  //     this.updateShowDropdowns(e.value);
  //   });
  //   showsDropdown.hide();
  //   this.updateShowDropdowns();
  // }

  private setupDropdowns() {
    const programmeDropdown = this.dropdowns["programmes"];
    const subProgrammeDropdown = this.dropdowns["subprogramme"];
    let dropdownData;

    // Programme dropdown
    dropdownData = [new DropdownItem("", "All Programmes")];
    showData.getProgrammesByShow().forEach(programme => {
      dropdownData.push(new DropdownItem(programme.slug, programme.title));
    });
    programmeDropdown.populate(dropdownData);
    programmeDropdown.on(DROPDOWN_SELECT, e => {
      const programmeId = e.value;
      if (programmeId.length) {
        this.showHeadings = false;
      }
      this.updateResults(showData.getProjectsByProgrammeAndUnit(programmeId));

      // Subprogramme dropdown
      const groupResults = showData.getGroupsByProgramme(programmeId);
      if (groupResults.length) {
        const groupName =
          groupResults[0].name !== "" ? groupResults[0].name + "s" : "";
        dropdownData = [new DropdownItem("", "All " + groupName)];
        groupResults.forEach(group => {
          if (group.value !== "") {
            dropdownData.push(new DropdownItem(group.value, group.value));
          }
        });
      } else {
        dropdownData = [new DropdownItem("", "All")];
        showData.getUnitsByProgramme(programmeId).forEach(unit => {
          dropdownData.push(new DropdownItem(unit.slug, unit.code));
        });
      }

      if (
        dropdownData.length > 2 &&
        e.value !== "" &&
        this.hideProgrammeUnits.indexOf(programmeId) < 0
      ) {
        subProgrammeDropdown.show();
      } else {
        console.log(2);
        subProgrammeDropdown.hide();
      }
      subProgrammeDropdown.populate(dropdownData);
      subProgrammeDropdown.on(DROPDOWN_SELECT, e => {
        const selectedProgramme = programmeDropdown.selectedKey;
        if (e.value.length) {
          this.showHeadings = false;
        }
        const groupResults = showData.getGroupsByProgramme(programmeId);
        const headingTitle = e.value ? e.title : "";
        if (groupResults.length) {
          this.updateResults(
            showData.getProjectsByProgrammeAndGroup(selectedProgramme, e.value),
            headingTitle
          );
        } else {
          this.updateResults(
            showData.getProjectsByProgrammeAndUnit(selectedProgramme, e.value),
            headingTitle
          );
        }
      });
    });
  }

  private resetShowFilters() {
    let dropdownData;
    const programmeDropdown = this.dropdowns["programmes"];

    // Programmes dropdown
    dropdownData = [new DropdownItem("", "All Programmes")];
    showData.json.show.programmes.forEach(programme => {
      dropdownData.push(new DropdownItem(programme.slug, programme.title));
    });
    programmeDropdown.populate(dropdownData);

    this.resetProgrammeUnitsFilters();
  }

  private resetProgrammeUnitsFilters() {
    let dropdownData;
    const unitsDropdown = this.dropdowns["subprogramme"];
    dropdownData = [new DropdownItem("", "All")];
    unitsDropdown.populate(dropdownData);
    unitsDropdown.hide();
  }

  // CATEGORY FILTERS - AWARDS
  private setupAwardFilters() {
    const awardsDropdown = this.dropdowns["awards"];
    awardsDropdown.on(DROPDOWN_SELECT, e => {
      if (e.value.length) {
        this.showHeadings = false;
      }
      this.updateResults(showData.getProjectsByAwardType(e.value));
    });
  }

  private resetAwardFilters() {
    // Awards dropdown
    const awardsDropdown = this.dropdowns["awards"];
    let dropdownData = [new DropdownItem("", "All Awards")];
    showData.getAwardTypes().forEach(award => {
      dropdownData.push(new DropdownItem(award, award));
    });
    awardsDropdown.populate(dropdownData);
  }

  // CATEGORY FILTERS - TAGS
  private setupTagFilters() {
    const tagsDropdown = this.dropdowns["tags"];
    tagsDropdown.on(DROPDOWN_SELECT, e => {
      if (e.value.length) {
        this.showHeadings = false;
      }
      console.log(e);
      this.updateResults(showData.getProjectsByTag(e.value));
    });
  }

  private resetTagFilters() {
    // Awards dropdown
    const tagsDropdown = this.dropdowns["tags"];
    let dropdownData = [new DropdownItem("", "All Themes")];
    showData.json.show.tags.forEach(tag => {
      dropdownData.push(new DropdownItem(tag.title, tag.title));
    });
    tagsDropdown.populate(dropdownData);
  }

  private resetStudents() {
    this.Autocomplete.populate("");
  }

  public show() {
    super.show();
    gallery3d.stopAnimation();
  }

  public hide() {
    super.hide();
    gallery3d.startAnimation();
  }

  public animateIn() {
    colorizer.changeColor(THEME_DARK);
    this.element.scrollTop = 0;

    const activeFilter = this.categoriesMenu.querySelectorAll(".active");
    const filterId = state.getValue(CURRENT_OVERLAY).filterId;

    if (filterId) {
      this.setActiveCategory(filterId);
    } else if (!activeFilter.length) {
      this.setActiveCategory("shows");
    }

    return super.animateIn();
  }

  public animateInComplete() {
    super.animateInComplete();
    if (!this.hasResults) {
      this.updateResults(showData.getProjectsByProgrammeAndUnit());
    }
  }

  public animateOut() {
    gsap.to(this.siteLogo, {
      duration: 0.5,
      opacity: 1,
      ease: "Power2.easeOut",
      clearProps: "all"
    });
    colorizer.changeColorBack();
    return super.animateOut();
  }

  private updateResults(projectResults: Array<any>, unitTitle: string = "") {
    this.hasResults = true;

    const resultsEl = this.element.querySelector(
      ".results-container .contents"
    );
    resultsEl.innerHTML = "";

    let lastProgrammeSlug = "";

    projectResults.forEach(projectData => {
      const projectIndexItem = new ProjectIndexItem(
        projectData,
        this.currentCategoryId
      );
      if (projectIndexItem.data.programme.slug != lastProgrammeSlug) {
        const headingEl = document.createElement("div");
        headingEl.classList.add("type-title--med");
        if (unitTitle !== "") {
          headingEl.innerHTML = `${projectIndexItem.data.programme.title}, ${unitTitle}`;
        } else {
          headingEl.innerHTML = projectIndexItem.data.programme.title;
        }
        resultsEl.append(headingEl);
        lastProgrammeSlug = projectIndexItem.data.programme.slug;
      }
      resultsEl.append(projectIndexItem.element);
    });
  }

  // Mobile Filter Bar: Moving of Filters element into or outside of the quick bar
  private toggleSticky(sticky: boolean) {
    const holder = this.filterSticky.querySelector(".holder");

    if (sticky) {
      this.filterBar.style.height = `${
        this.filterBar.getBoundingClientRect().height
      }px`;
      holder.appendChild(this.filters);
      this.filterToggleButton.classList.add("visible");
    } else {
      this.filterBar.style.height = "auto";
      this.filterBar.appendChild(this.filters);
      this.filterToggleButton.classList.remove("visible");
    }
  }

  // Mobile Filter Bar: Sliding down the background
  private toggleBackgroundBar(sticky: boolean) {
    const duration = 0.3;
    const ease = "Power2.easeOut";

    if (sticky) {
      this.filterSticky.classList.add("visible");

      gsap.to(this.siteLogo, {
        duration,
        opacity: 0,
        ease,
        onComplete: () => {
          this.siteLogo.style.pointerEvents = "none";
        }
      });
      gsap.to(this.filterSticky, { duration, y: 0, ease });
    } else {
      this.filterSticky.classList.remove("visible");
      gsap.to(this.siteLogo, { duration, opacity: 1, ease, clearProps: "all" });
      gsap.to(this.filterSticky, { duration, y: "-100%", ease });
    }
  }

  // Mobile Filter Bar: Opening and closing the Filters
  private toggleFilterBar() {
    const filtersHeight = this.filters.getBoundingClientRect().height;

    if (this.filterToggleButton.classList.contains("active")) {
      this.filterToggleButton.classList.remove("active");
      this.filterSticky.style.overflow = "hidden";

      gsap.to(this.filterSticky, {
        duration: 0.5,
        height: this.filterStickyHeight,
        ease: "Power2.easeOut",
        onComplete: () => {}
      });
    } else {
      this.filterStickyHeight = this.filterSticky.getBoundingClientRect().height;
      this.filterToggleButton.classList.add("active");

      gsap.to(this.filterSticky, {
        duration: 0.5,
        height: filtersHeight + this.filterStickyHeight,
        ease: "Power2.easeOut",
        onComplete: () => {
          this.filterSticky.style.height = "auto";
          this.filterSticky.style.overflow = "visible";
        }
      });
    }
  }

  public onResize() {
    if (WindowManager.width > Globals.BP_MD) {
      this.toggleSticky(false);
    }
  }

  public onScroll() {
    const heightForBackgroundToShow = 80;
    if (
      this.element.scrollTop > WindowManager.height &&
      WindowManager.width < Globals.BP_MD
    ) {
      this.toggleSticky(true);
    } else {
      this.toggleSticky(false);
    }

    if (
      this.element.scrollTop > heightForBackgroundToShow &&
      WindowManager.width < Globals.BP_MD
    ) {
      this.toggleBackgroundBar(true);
    } else {
      this.toggleBackgroundBar(false);
    }
  }
}

export const projectIndexPage = new ProjectIndexPage(
  document.querySelector(".ProjectIndexPage")
);
